var $ = jQuery.noConflict();

$(window).on('load', function () {
  let method = 'front';
  if ($('body').hasClass('wp-admin')) method = 'wp-admin';

  var initializeBlock = function ($block) {
    $('.block-project-carousel .swiper').each(function () {
      var blockId = $(this).closest('.block-project-carousel').attr('id');

      var swiperInit = new Swiper(this, {
        slidesPerView: 3,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          767: {
            slidesPerView: 2,
          },
          960: {
            slidesPerView: 3,
          },
        },
        autoHeight: true,
        loop: true,
        spaceBetween: 30,
        slideClass: 'swiper-slide',
        navigation: {
          prevEl: '#' + blockId + ' .btn-circle--prev',
          nextEl: '#' + blockId + ' .btn-circle--next',
        },
      });

      $(this).closest('.block-project-carousel').addClass('load');
    });
  };

  window.onload = (function () {
    initializeBlock($(this));
  })();

  if (window.acf) {
    window.acf.addAction(
      'render_block_preview/type=project-carousel',
      initializeBlock
    );
  }
});
